import { ReactNode, useEffect, useState } from 'react';
import Link, { LinkProps } from 'next/link';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useRouter } from 'next/dist/client/router';

import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      marginRight: theme.spacing(1),
      transition: '0.4s',
      cursor: 'pointer',

      '&:hover': {
        transform: 'translateY(-8px)',
        '& a': {
          color: theme.palette.primary.main
        }
      }
    },
    isMobileRoot: {
      marginRight: 0,
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      '&:hover': {
        transform: 'none'
      }
    },
    innerLink: {
      textDecoration: 'none',
      fontSize: '18px',
      color: theme.palette.primary.light,
      fontWeight: 'bold',
      transition: '0.4s',
      paddingRight: theme.spacing(1)
    },
    isMobileInnerLink: {
      fontSize: '24px',
      paddingRight: 0
    },

    innerLinkActive: {
      color: theme.palette.primary.main
    }
  };
});

interface Props extends LinkProps {
  children: ReactNode;
  isMobile?: boolean;
  onClick?: () => void;
  className?: string;
}

const HeaderListItemLink = ({
  children,
  isMobile = false,
  href,
  onClick,
  className,
  ...other
}: Props) => {
  const classes = useStyles();
  const router = useRouter();

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const currentPath = router?.route;
    setIsActive(currentPath === href);
  }, [router, href]);

  return (
    <li
      className={classNames(
        className,
        { [classes.isMobileRoot]: isMobile },
        classes.root
      )}
    >
      <Link href={href} {...other}>
        <a
          onClick={onClick}
          className={classNames(classes.innerLink, {
            [classes.innerLinkActive]: isActive,
            [classes.isMobileInnerLink]: isMobile
          })}
        >
          {children}
        </a>
      </Link>
    </li>
  );
};

export default HeaderListItemLink;
