import { makeStyles } from '@material-ui/core/styles';
import { Grid, Theme, Fade, Typography } from '@material-ui/core';

import React, { useState, useEffect } from 'react';

import Image from 'next/image';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      maxWidth: '1140px',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    container: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    image: {
      borderRadius: theme.shape.borderRadius
    },
    author: {
      color: theme.palette.primary.light
    },
    quote: {
      color: theme.palette.primary.main
    },
    quoteContainer: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(8)
      }
    }
  };
});

interface Quote {
  quote: string;
  author: string;
}

const quotes: Quote[] = [
  {
    quote:
      '“The system has been developed for governors BY governors, who understand what is required to make the job easier and resources more accessible. I hope you gather I am a great fan.” ',
    author: 'Becky, Governance Lead in a Multi-Academy Trust'
  },
  {
    quote:
      '“I haven’t met a Governor who does not like GovernorHub, by the way!”',
    author: 'Clerk, Hounslow'
  },
  {
    quote: '“I find GovernorHub invaluable as a resource.”',
    author: 'Governor, Buckinghamshire'
  },
  {
    quote:
      '“It is visually seamless, one of the easiest things I have ever used. It does exactly what you would want it to be able to do and serves an enormous purpose!”',
    author: 'Governor, Nottingham'
  },
  {
    quote:
      '“I absolutely love this software. It’s fantastic, simple and easy to navigate and very user friendly."',
    author: 'Governor in Dudley'
  },
  {
    quote:
      '“Feedback on GovernorHub is very positive across our schools; it’s really a big help in keeping us organised.” ',
    author: 'Susan, Multi-Academy Trust Operations Director'
  },
  {
    quote:
      '“I have been a governor for years, and GovernorHub is amazing! It revolutionised communication between my school and myself.”',
    author: 'Katy Davison, Chair of Windmill Hill Primary School'
  },
  {
    quote:
      '“I do many external reviews of governance, and I so often find that those who use GovernorHub have a high level of communication, organisation and effectiveness. Your site really supports strong governance.”',
    author: 'Jackie Eason MBE, National Leader of Governance'
  }
];

const Recommendations = () => {
  const classes = useStyles();

  const [currentQuote, setCurrentQuote] = useState(0);
  const [fading, setFading] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFading(false);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [currentQuote]);

  return (
    <div className={classes.root}>
      <Grid container className={classes.container} alignItems="center">
        <Grid item sm={6}>
          <Image
            src="https://static.governorhub.com/assets/images/board-meeting.JPG"
            title="GovernorHub Recommendations Image"
            className={classes.image}
            height={2551}
            width={3826}
            priority={true}
          />
        </Grid>
        <Grid item sm={6} className={classes.quoteContainer}>
          <Fade
            in={fading}
            onExited={() => {
              setFading(true);
              setCurrentQuote(
                currentQuote < quotes.length - 1 ? currentQuote + 1 : 0
              );
            }}
          >
            <div>
              <Typography variant="h3" className={classes.quote}>
                {quotes[currentQuote].quote}
              </Typography>
              <Typography variant="caption" className={classes.author}>
                - {quotes[currentQuote].author}
              </Typography>
            </div>
          </Fade>
        </Grid>
      </Grid>
    </div>
  );
};

export default Recommendations;
