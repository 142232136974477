import { ReactNode } from 'react';

import { makeStyles, Theme } from '@material-ui/core';

import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => {
  return {
    content: {
      display: 'flex',
      maxWidth: '1140px',
      marginLeft: 'auto',
      marginRight: 'auto',
      position: 'relative',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      flexDirection: 'column'
    }
  };
});

export interface Props {
  children: ReactNode;
  className?: string;
}

const GHContent = ({ className, children }: Props) => {
  const classes = useStyles();

  return (
    <div className={classNames(className, classes.content)}>{children}</div>
  );
};

export default GHContent;