import { createTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: 'modernera, sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '3rem',
      marginBottom: '0.9rem',
      color: '#13263f'
    },
    h2: {
      fontWeight: 700,
      fontSize: '2rem',
      marginBottom: '0.9rem',
      color: '#13263f'
    },
    h3: {
      fontWeight: 700,
      fontSize: '1.4rem',
      marginBottom: '0.9rem',
      color: '#13263f'
    },
    h4: {
      fontWeight: 700,
      fontSize: '1.1rem',
      marginBottom: '0.9rem',
      color: 'rgba(0,143,225, 1)'
    },

    body1: {
      fontWeight: 400,
      fontSize: '1.1rem',
      color: '#13263f'
    },
    body2: {
      fontWeight: 400,
      fontSize: '1rem',
      marginBottom: '0.9rem',
      color: '#13263f'
    },
    caption: {
      fontWeight: 700,
      fontSize: '0.9rem',
      marginBottom: '0.9rem',
      color: '#D1E0ED'
    }
  },
  palette: {
    primary: {
      main: '#13263f',
      light: '#9DAEAD'
    },
    secondary: {
      light: 'rgba(0,143,225, 0.2)',
      main: 'rgba(0,143,225, 0.5)',
      dark: 'rgba(0,143,225, 0.9)'
    },
    error: {
      main: red.A400
    },
    background: {
      default: '#fdfffc'
    },
    text: {
      secondary: '#fdfffc'
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: { marginBottom: 0 }
      }
    }
  }
});

export default theme;
