import { ReactNode } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      marginRight: theme.spacing(1),
      transition: '0.4s',
      cursor: 'pointer',

      '&:hover': {
        transform: 'translateY(-8px)',
        '& a': {
          color: theme.palette.primary.main,
        },
      },
    },
    isMobileRoot: {
      marginRight: 0,
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      '&:hover': {
        transform: 'none',
      },
    },
    innerLink: {
      textDecoration: 'none',
      fontSize: '18px',
      color: theme.palette.primary.light,
      fontWeight: 'bold',
      transition: '0.4s',
      paddingRight: theme.spacing(1),
    },
    isMobileInnerLink: {
      fontSize: '24px',
      paddingRight: 0,
    },
  };
});

interface Props {
  children: ReactNode;
  isMobile?: boolean;
  onClick?: () => void;
  className?: string;
}

const HeaderListItemLinkExternal = ({
  children,
  isMobile = false,
  onClick,
  className,
}: Props) => {
  const classes = useStyles();

  return (
    <li
      className={classNames(
        className,
        { [classes.isMobileRoot]: isMobile },
        classes.root
      )}
    >
      <a
        onClick={onClick}
        className={classNames(classes.innerLink, {
          [classes.isMobileInnerLink]: isMobile,
        })}
      >
        {children}
      </a>
    </li>
  );
};

export default HeaderListItemLinkExternal;
