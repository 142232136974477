import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { ClickAwayListener, Collapse } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import classNames from 'classnames';

import HeaderListItemLink from './HeaderListItemLink';

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    features: {
      transition: '0.4s',
      cursor: 'pointer',
      textDecoration: 'none',
      fontSize: '24px',
      color: theme.palette.primary.light,
      fontWeight: 'bold',
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    featuresOpen: {
      color: theme.palette.primary.main,
    },
    expand: {
      backgroundColor: theme.palette.grey[100],
      marginBottom: theme.spacing(2),
    },
  };
});

export const FeaturesCollapse = ({ setOpen }: Props) => {
  const [expand, setExpand] = useState(false);
  const classes = useStyles();
  const router = useRouter();
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    const currentPath = router?.route;
    const active =
      currentPath === '/features' || currentPath === '/ksg-features';
    setIsActive(active);
    setExpand(active);
  }, [router]);
  return (
    <ClickAwayListener onClickAway={() => setExpand(isActive)}>
      <span>
        <li
          className={classNames(classes.features, {
            [classes.featuresOpen]: expand || isActive,
          })}
          onClick={() => setExpand((oldExpand) => !oldExpand)}
        >
          Features
        </li>

        <Collapse in={expand}>
          <div className={classes.expand}>
            <HeaderListItemLink
              isMobile
              href="/features"
              onClick={() => setOpen(false)}
            >
              GovernorHub
            </HeaderListItemLink>
            <HeaderListItemLink
              isMobile
              href="/ksg-features"
              onClick={() => setOpen(false)}
            >
              GovernorHub Knowledge
            </HeaderListItemLink>
          </div>
        </Collapse>
      </span>
    </ClickAwayListener>
  );
};
