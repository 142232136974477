import React, { useState, useRef, useEffect } from 'react';

import classNames from 'classnames';

import { useRouter } from 'next/router';

import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  MenuList,
  Popper,
  Paper,
  ClickAwayListener,
  Grow
} from '@material-ui/core';

import HeaderListItemLink from './HeaderListItemLink';

const useStyles = makeStyles((theme: Theme) => {
  return {
    features: {
      marginRight: theme.spacing(1),
      transition: '0.4s',
      cursor: 'pointer',
      textDecoration: 'none',
      fontSize: '18px',
      color: theme.palette.primary.light,
      fontWeight: 'bold',
      paddingRight: theme.spacing(1)
    },
    featuresOpen: {
      color: theme.palette.primary.main
    },
    menuList: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      margin: 0
    },
    marginTop: {
      marginTop: theme.spacing(2)
    }
  };
});

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const FeaturesDropDown = ({ setOpen }: Props) => {
  const classes = useStyles();
  const [featuresOpen, setFeaturesOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setFeaturesOpen(false);
  };

  const router = useRouter();
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    const currentPath = router?.route;
    setIsActive(currentPath === '/features' || currentPath === '/ksg-features');
  }, [router]);

  return (
    <div>
      <li
        className={classNames(classes.features, {
          [classes.featuresOpen]: featuresOpen || isActive
        })}
        onMouseOver={() => setFeaturesOpen(true)}
        onMouseOut={() => setFeaturesOpen(false)}
        onClick={() => setFeaturesOpen(true)}
        ref={anchorRef as any}
      >
        Features
      </li>
      <Popper
        open={featuresOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        onMouseOver={() => setFeaturesOpen(true)}
        onMouseOut={() => setFeaturesOpen(false)}
        placement={'bottom-start'}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'top left'
            }}
          >
            <Paper elevation={1}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList className={classes.menuList}>
                  <HeaderListItemLink
                    className={classes.marginTop}
                    href="/features"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    GovernorHub
                  </HeaderListItemLink>
                  <HeaderListItemLink
                    className={classes.marginTop}
                    href="/ksg-features"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    GovernorHub Knowledge
                  </HeaderListItemLink>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default FeaturesDropDown;
