import { makeStyles } from '@material-ui/core/styles';
import { Grid, Theme, Typography } from '@material-ui/core';

import GHButton from '../common/GHButton';
import GHContent from '../common/GHContent';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.text.secondary,
      textAlign: 'center',
      position: 'relative',
    },

    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    noMargin: {
      margin: 0, // remove margin from text elements
    },
    whiteText: {
      color: theme.palette.text.secondary,
    },
  };
});

const Bait = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GHContent>
        <Grid
          container
          className={classes.container}
          alignItems="center"
          direction="column"
          justifyContent="center"
        >
          <Typography variant="h2" className={classes.whiteText}>
            Like what you see?
          </Typography>
          <Typography
            variant="body1"
            className={classes.whiteText}
            gutterBottom
          >
            All schools and trusts in England can try GovernorHub with
            GovernorHub Knowledge for a no-obligation, 1 month, unlimited-use
            trial.
          </Typography>
          <Grid item container spacing={1} justifyContent="center">
            <Grid item></Grid>
            <Grid item>
              <GHButton href="/signup" variant="outlined">
                Try For Free
              </GHButton>
            </Grid>
          </Grid>
        </Grid>
      </GHContent>
    </div>
  );
};

export default Bait;
