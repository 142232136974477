import Link from 'next/link';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { ReactNode } from 'react';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      cursor: 'pointer',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      borderWidth: '2px',
      borderStyle: 'solid',
      transition: '0.4s'
    },
    contained: {
      backgroundColor: theme.palette.secondary.dark,
      borderColor: theme.palette.secondary.dark,
      color: theme.palette.text.secondary,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    },
    outlined: {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: theme.palette.text.secondary,
      color: theme.palette.text.secondary,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    },
    inverseOutlined: {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.dark,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: theme.palette.text.secondary
      }
    }
  };
});

export interface GHButtonProps {
  children: ReactNode;
  href?: string | undefined;
  variant?: 'outlined' | 'contained' | 'inverse-outlined';
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const GHButton = ({
  children,
  href,
  variant = 'contained',
  className,
  disabled = false,
  onClick
}: GHButtonProps) => {
  const classes = useStyles();

  const renderButton = () => {
    return (
      <button
        onClick={onClick}
        disabled={disabled}
        className={classNames(classes.root, className, {
          [classes.contained]: variant === 'contained',
          [classes.outlined]: variant === 'outlined',
          [classes.inverseOutlined]: variant === 'inverse-outlined'
        })}
      >
        {children}
      </button>
    );
  };
  return href ? <Link href={href}>{renderButton()}</Link> : renderButton();
};

export default GHButton;
