import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Theme,
  useMediaQuery,
  useTheme,
  Typography
} from '@material-ui/core';

import Image from 'next/image';

import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';

import GHContent from '../common/GHContent';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      color: theme.palette.secondary.dark,
      textAlign: 'center',
      position: 'relative',
      paddingBottom: theme.spacing(8)
    },
    image: {
      objectFit: 'contain'
    },
    imageContainer: {
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center'
    },
    slide: { display: 'flex' }
  };
});

const IMAGE_ROOT = '/customers/';

const images = [
  `${IMAGE_ROOT}3bm.jpg`,
  `${IMAGE_ROOT}achieving-for-children.png`,
  `${IMAGE_ROOT}attinghameducation.png`,
  `${IMAGE_ROOT}balance.png`,
  `${IMAGE_ROOT}barnet-education-and-learning-service.jpeg`,
  `${IMAGE_ROOT}barnsley.jpeg`,
  `${IMAGE_ROOT}bedford.png`,
  `${IMAGE_ROOT}BEP.png`,
  `${IMAGE_ROOT}blackpool.png`,
  `${IMAGE_ROOT}bristol.png`,
  `${IMAGE_ROOT}camden.png`,
  `${IMAGE_ROOT}calderdale.jpeg`,
  `${IMAGE_ROOT}cheshireeast.png`,
  `${IMAGE_ROOT}clerks-of-london.png`,
  `${IMAGE_ROOT}cooper-barton.jpg`,
  `${IMAGE_ROOT}coventry.jpg`,
  `${IMAGE_ROOT}derby.png`,
  `${IMAGE_ROOT}devon-education-services.png`,
  `${IMAGE_ROOT}dioceseofwestminster.png`,
  `${IMAGE_ROOT}dneat.png`,
  `${IMAGE_ROOT}dudley.jpg`,
  `${IMAGE_ROOT}ealing.png`,
  `${IMAGE_ROOT}edsential.png`,
  `${IMAGE_ROOT}educationpeople.jpg`,
  `${IMAGE_ROOT}enfield.png`,
  `${IMAGE_ROOT}entrust.png`,
  `${IMAGE_ROOT}governanceconnected.png`,
  `${IMAGE_ROOT}governanceresolutions.png`,
  `${IMAGE_ROOT}haringey.jpg`,
  `${IMAGE_ROOT}havering.jpg`,
  `${IMAGE_ROOT}hes.jpg`,
  `${IMAGE_ROOT}hfl-education.png`,
  `${IMAGE_ROOT}hounslow.png`,
  `${IMAGE_ROOT}islington.png`,
  `${IMAGE_ROOT}kensingtonandchelsea.jpeg`,
  `${IMAGE_ROOT}kirklees.png`,
  `${IMAGE_ROOT}learnsheffield.jpg`,
  `${IMAGE_ROOT}lincolnshire.jpg`,
  `${IMAGE_ROOT}luton.png`,
  `${IMAGE_ROOT}medway.png`,
  `${IMAGE_ROOT}mk.png`,
  `${IMAGE_ROOT}norfolk.png`,
  `${IMAGE_ROOT}north-northants.png`,
  `${IMAGE_ROOT}nottingham.png`,
  `${IMAGE_ROOT}notts.png`,
  `${IMAGE_ROOT}oxfordshire.png`,
  `${IMAGE_ROOT}reach2.png`,
  `${IMAGE_ROOT}reading.png`,
  `${IMAGE_ROOT}rochdale.png`,
  `${IMAGE_ROOT}s4s.png`,
  `${IMAGE_ROOT}salford.png`,
  `${IMAGE_ROOT}schoolimprovementliverpool.png`,
  `${IMAGE_ROOT}schools-alliance-for-excellence.png`,
  `${IMAGE_ROOT}schoolschoice.png`,
  `${IMAGE_ROOT}sips.jpeg`,
  `${IMAGE_ROOT}solihull.jpeg`,
  `${IMAGE_ROOT}southglos.png`,
  `${IMAGE_ROOT}stockport.png`,
  `${IMAGE_ROOT}stokeontrent.png`,
  `${IMAGE_ROOT}strictlyeducation4s.jpeg`,
  `${IMAGE_ROOT}swindon.png`,
  `${IMAGE_ROOT}trustgs.png`,
  `${IMAGE_ROOT}walsall.jpg`,
  `${IMAGE_ROOT}warwickshire.jpeg`,
  `${IMAGE_ROOT}westberks.png`,
  `${IMAGE_ROOT}west-northants.png`,
  `${IMAGE_ROOT}westminster.png`,
  `${IMAGE_ROOT}wigan.png`
];

const Partners = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.root}>
      <GHContent>
        <Grid container justifyContent="center">
          <Typography variant="h2">
            Sign up through your local provider
          </Typography>
          <Grid item xs={12}>
            <CarouselProvider
              naturalSlideWidth={100}
              interval={5000}
              naturalSlideHeight={80}
              totalSlides={images.length}
              isPlaying
              infinite
              step={matchesSm ? 2 : 5}
              visibleSlides={matchesSm ? 2 : 5}
            >
              <Slider>
                {images.map((image, index) => {
                  return (
                    <Slide
                      key={index}
                      index={index}
                      className={classes.slide}
                      innerClassName={classes.imageContainer}
                    >
                      <Image
                        src={image}
                        className={classes.image}
                        width="150"
                        height="150"
                      />
                    </Slide>
                  );
                })}
              </Slider>
            </CarouselProvider>
          </Grid>
        </Grid>
      </GHContent>
    </div>
  );
};

{
}

export default Partners;
